var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-vue"},[_c('div',{staticClass:"vld-parent"},[_c('loading',{attrs:{"active":_vm.isLoading,"color":'#00b8ee',"is-full-page":true,"blur":"0"},on:{"update:active":function($event){_vm.isLoading=$event}}})],1),(_vm.whitelabel === 'EagleGlobalMarkets' || _vm.whitelabel === 'TradeCoreUK')?_c('div',{staticClass:"bg-white lg:border-t lg:border-b lg:border-gray-200 mx-auto"},[_c('nav',{staticClass:"mx-auto max-w-6xl px-4 sm:px-6 lg:px-8"},[_c('ul',{staticClass:"rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none"},_vm._l((_vm.get_signup_data.step_meta),function(step,index){return _c('li',{key:index,staticClass:"relative overflow-hidden lg:flex-1"},[_c('div',{staticClass:"border border-gray-200 overflow-hidden border-b-0 rounded-t-md lg:border-0"},[_c('a',{staticClass:"group"},[_c('div',{staticClass:"absolute top-0 left-0 w-1 h-full bg-transparent lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"}),_c('div',{staticClass:"px-6 py-5 flex items-start text-sm leading-5 font-medium space-x-4"},[(index === _vm.$store.getters.show_next_step)?_c('div',{staticClass:"absolute top-0 left-0 w-1 h-full bg-indigo-600 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"}):_vm._e(),(index < step)?_c('div',{staticClass:"flex-shrink-0"},[_c('div',{staticClass:"w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full"},[_c('svg',{staticClass:"w-6 h-6 text-white",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"clip-rule":"evenodd","d":"M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z","fill-rule":"evenodd"}})])])]):_c('div',{staticClass:"flex-shrink-0"},[_c('div',{staticClass:"w-10 h-10 flex items-center justify-center border-2 rounded-full",class:index === _vm.$store.getters.show_next_step
                        ? 'border-indigo-600'
                        : 'border-gray-300'},[_c('p',{class:index === _vm.$store.getters.show_next_step
                          ? 'text-indigo-600'
                          : 'text-gray-400'},[_vm._v(" "+_vm._s(index + 1)+" ")])])]),_c('div',{staticClass:"mt-0.5 min-w-0"},[_c('h3',{staticClass:"text-xs leading-4 font-semibold uppercase tracking-wide",class:index === _vm.$store.getters.show_next_step
                        ? 'active-font-color'
                        : ''},[(index <= _vm.$store.getters.show_next_step)?_c('span',[_vm._v(" "+_vm._s(step[0].title)+" ")]):_c('span',[_vm._v(" "+_vm._s(step[0].title)+" ")])])])])])]),(0 < index)?_c('div',{staticClass:"hidden absolute top-0 left-0 w-3 inset-0 lg:block"},[_c('svg',{staticClass:"h-full w-full text-gray-300",attrs:{"fill":"none","preserveAspectRatio":"none","viewBox":"0 0 12 82"}},[_c('path',{attrs:{"d":"M0.5 0V31L10.5 41L0.5 51V82","stroke":"currentcolor","vector-effect":"non-scaling-stroke"}})])]):_vm._e()])}),0)])]):_vm._e(),[(_vm.get_signup_data.step_meta)?_c('div',{staticClass:"max-w-3xl mx-auto min-h-screen p-4"},[_c('div',{staticClass:"mt-10 pb-10"},_vm._l((_vm.get_signup_data.step_meta),function(step,index){return (index === _vm.$store.getters.show_next_step)?_c('Form',{key:index,attrs:{"id":index,"demo-form":_vm.whitelabel !== 'TD365' &&
            _vm.whitelabel !== 'TDSouthAfrica' &&
            _vm.whitelabel !== 'OneTradingMarkets',"forms":_vm.get_signup_data.actions.GET.fields,"live-form":_vm.whitelabel === 'TD365' ||
            _vm.whitelabel === 'TDSouthAfrica' ||
            _vm.whitelabel === 'OneTradingMarkets',"numberOfSteps":_vm.get_signup_data.step_meta.length - 1,"step":step,"tradingPlatforms":_vm.get_demo_trading_platforms,"classes":"shadow rounded-md","egm-demo":""}}):_vm._e()}),1)]):_vm._e()],_c('Footer')],2)}
var staticRenderFns = []

export { render, staticRenderFns }