<template>
  <div>
    <Header>
      <template v-slot:title>
        <span v-if="whitelabel === 'Blackstone'">
          {{ $t("add-new-live-account") }}
        </span>
        <span v-else>
          {{ $t("open-live-account") }}
        </span>
      </template>
    </Header>
    <div class="rounded-md min-h-screen">
      <div>
        <div class="border-t border-gray-200">
          <div class="flex flex-col">
            <div>
              <div class="align-middle inline-block min-w-full">
                <div class="border-b border-gray-200">
                  <!--      steps-->

                  <!--      Forms-->
                  <div
                    v-if="get_signup_data.step_meta"
                    class="max-w-3xl mx-auto min-h-screen"
                  >
                    <div class="mt-10 pb-10">
                      <Form
                        v-for="(step, index) in get_signup_data.step_meta"
                        v-if="index === $store.getters.show_next_step"
                        :id="index"
                        :key="index"
                        :demo-form="false"
                        :forms="get_signup_data.actions.GET.fields"
                        :numberOfSteps="get_signup_data.step_meta.length - 1"
                        :open-live-form="true"
                        :step="step"
                        :tradingPlatforms="get_trading_platforms"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "@/views/register/Form";
import { mapGetters } from "vuex";
import Header from "@/components/Header";

export default {
  components: {
    Form,
    Header,
  },
  data() {
    return {
      isLoading: false,
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  computed: {
    ...mapGetters(["get_trading_platforms", "get_signup_data"]),
    lang() {
      return this.$store.getters.get_lang;
    },
  },
  watch: {
    lang() {
      if (this.whitelabel === "TradeCoreUK") {
        this.$store
          .dispatch("set_signup_data_open_live_account_with_whitelabel")
          .then(() => {
            this.isLoading = false;
          });
      } else {
        this.$store.dispatch("set_signup_data_open_live_account").then(() => {
          this.isLoading = false;
        });
      }
    },
  },
  beforeCreate() {
    if (!localStorage.getItem("step")) {
      localStorage.setItem("step", "0");
    }
  },
  created() {
    this.isLoading = true;
    if (this.whitelabel === "TradeCoreUK") {
      this.$store
        .dispatch("set_signup_data_open_live_account_with_whitelabel")
        .then(() => {
          this.isLoading = false;
        });
    } else {
      this.$store.dispatch("set_signup_data_open_live_account").then(() => {
        this.isLoading = false;
      });
    }

    this.$store.dispatch("upgrade_trading_platforms").then(() => {
      this.isLoading = false;
    });

    // if (Cookie.get('x-tracking-id')) {
    //   this.$store.dispatch('display_trading_platforms_tracking_links').then(() => {
    //     this.isLoading = false
    //   })
    // } else {
    //   this.$store.dispatch('upgrade_trading_platforms').then(() => {
    //         this.isLoading = false
    //       }
    //   )
    // }

    // if (Cookie.get('x-tracking-id')) {
    //   this.$store.dispatch('display_trading_platforms_tracking_links').then(() => {
    //     this.isLoading = false
    //   })
    // } else {
    //   this.$store.dispatch('display_trading_platforms').then(() => {
    //         this.isLoading = false
    //       }
    //   )
    // }
  },
};
</script>
