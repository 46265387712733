<template>
  <div class="register-vue">
    <!--    Loader-->
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        :color="'#00b8ee'"
        :is-full-page="true"
        blur="0"
      ></loading>
    </div>

    <Navigation
      :show-language-switcher="true"
      :show-user-menu="false"
      :showLogin="true"
    />

    <div
      v-if="whitelabel === 'EagleGlobalMarkets' || whitelabel === 'TradeCoreUK'"
      class="bg-white lg:border-t lg:border-b lg:border-gray-200 mx-auto"
    >
      <nav class="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
        <ul
          class="rounded-md overflow-hidden lg:flex lg:border-l lg:border-r lg:border-gray-200 lg:rounded-none"
        >
          <li
            v-for="(step, index) in get_signup_data.step_meta"
            :key="index"
            class="relative overflow-hidden lg:flex-1"
          >
            <div
              class="border border-gray-200 overflow-hidden border-b-0 rounded-t-md lg:border-0"
            >
              <a class="group">
                <div
                  class="absolute top-0 left-0 w-1 h-full bg-transparent lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                ></div>
                <div
                  class="px-6 py-5 flex items-start text-sm leading-5 font-medium space-x-4"
                >
                  <div
                    v-if="index === $store.getters.show_next_step"
                    class="absolute top-0 left-0 w-1 h-full bg-indigo-600 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                  ></div>
                  <div v-if="index < step" class="flex-shrink-0">
                    <div
                      class="w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full"
                    >
                      <!-- Heroicon name: check -->
                      <svg
                        class="w-6 h-6 text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clip-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          fill-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <div v-else class="flex-shrink-0">
                    <div
                      :class="
                        index === $store.getters.show_next_step
                          ? 'border-indigo-600'
                          : 'border-gray-300'
                      "
                      class="w-10 h-10 flex items-center justify-center border-2 rounded-full"
                    >
                      <p
                        :class="
                          index === $store.getters.show_next_step
                            ? 'text-indigo-600'
                            : 'text-gray-400'
                        "
                      >
                        {{ index + 1 }}
                      </p>
                    </div>
                  </div>
                  <div class="mt-0.5 min-w-0">
                    <h3
                      :class="
                        index === $store.getters.show_next_step
                          ? 'active-font-color'
                          : ''
                      "
                      class="text-xs leading-4 font-semibold uppercase tracking-wide"
                    >
                      <span v-if="index <= $store.getters.show_next_step">
                        {{ step[0].title }}
                      </span>
                      <span v-else>
                        {{ step[0].title }}
                      </span>
                    </h3>
                  </div>
                </div>
              </a>
            </div>
            <div
              v-if="0 < index"
              class="hidden absolute top-0 left-0 w-3 inset-0 lg:block"
            >
              <svg
                class="h-full w-full text-gray-300"
                fill="none"
                preserveAspectRatio="none"
                viewBox="0 0 12 82"
              >
                <path
                  d="M0.5 0V31L10.5 41L0.5 51V82"
                  stroke="currentcolor"
                  vector-effect="non-scaling-stroke"
                />
              </svg>
            </div>
          </li>
        </ul>
      </nav>
    </div>

    <!--      Forms -->
    <template v-if="EGMdemo">
      <div
        v-if="get_signup_data.step_meta"
        class="max-w-3xl mx-auto min-h-screen p-4"
      >
        <div class="mt-10 pb-10">
          <Form
            v-for="(step, index) in get_signup_data.step_meta"
            v-if="index === $store.getters.show_next_step"
            :id="index"
            :key="index"
            :demo-form="whitelabel === 'EagleGlobalMarkets'"
            :forms="get_signup_data.actions.GET.fields"
            :numberOfSteps="get_signup_data.step_meta.length - 1"
            :step="step"
            :tradingPlatforms="get_demo_trading_platforms"
            classes="shadow rounded-md"
            egm-demo
          />
        </div>
      </div>
    </template>
    <template
      v-if="
        whitelabel === 'PolarisMarkets' ||
        whitelabel === 'Dzengi' ||
        (whitelabel === 'ICMVC' && stage === 'production') ||
        (whitelabel === 'ICMMU' && stage === 'production')
      "
    >
      <div
        v-if="get_signup_data.step_meta"
        class="max-w-3xl mx-auto min-h-screen p-4"
      >
        <div class="mt-10 pb-10">
          <Form
            v-for="(step, index) in get_signup_data.step_meta"
            v-if="index === $store.getters.show_next_step"
            :id="index"
            :key="index"
            :demo-form="true"
            :forms="get_signup_data.actions.GET.fields"
            :numberOfSteps="get_signup_data.step_meta.length - 1"
            :step="step"
            :tradingPlatforms="get_demo_trading_platforms"
            classes="shadow rounded-md"
          />
        </div>
      </div>
    </template>

    <template v-else-if="whitelabel === 'TradeCoreUK'">
      <div
        v-if="get_signup_data.step_meta"
        class="max-w-3xl mx-auto min-h-screen p-4"
      >
        <div class="mt-10 pb-10">
          <Form
            v-for="(step, index) in get_signup_data.step_meta"
            v-if="index === $store.getters.show_next_step"
            :id="index"
            :key="index"
            :demo-form="whitelabel === 'TradeCoreUK'"
            :forms="get_signup_data.actions.GET.fields"
            :numberOfSteps="get_signup_data.step_meta.length - 1"
            :step="step"
            :tradingPlatforms="get_demo_trading_platforms"
            classes="shadow rounded-md"
          />
        </div>
      </div>
    </template>

    <template v-else>
      <div
        v-if="get_signup_data.step_meta"
        class="max-w-3xl mx-auto min-h-screen p-4"
      >
        <div class="mt-10 pb-10">
          <Form
            v-for="(step, index) in get_signup_data.step_meta"
            v-if="index === $store.getters.show_next_step"
            :id="index"
            :key="index"
            :demo-form="
              whitelabel !== 'TD365' &&
              whitelabel !== 'TDSouthAfrica' &&
              whitelabel !== 'OneTradingMarkets' &&
              whitelabel !== 'EagleGlobalMarkets' &&
              whitelabel !== 'ICMVC' &&
              whitelabel !== 'ICMMU'
            "
            :forms="get_signup_data.actions.GET.fields"
            :live-form="
              whitelabel === 'TD365' ||
              whitelabel === 'TDSouthAfrica' ||
              whitelabel === 'OneTradingMarkets' ||
              whitelabel === 'EagleGlobalMarkets' ||
              (whitelabel === 'ICMVC' && stage !== 'production') ||
              (whitelabel === 'ICMMU' && stage !== 'production')
            "
            :numberOfSteps="get_signup_data.step_meta.length - 1"
            :step="step"
            :tradingPlatforms="get_trading_platforms"
            classes="shadow rounded-md"
          />
        </div>
      </div>
    </template>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import Form from "@/views/register/Form";
import { mapGetters } from "vuex";
import Cookie from "js-cookie";
import { set_demo_signup_data } from "@/store/actions";

export default {
  components: {
    Footer,
    Navigation,
    Form,
  },
  data() {
    return {
      isLoading: false,
      whitelabel: process.env.VUE_APP_WHITELABEL,
      data: "",
      EGMdemo: false,
      stage: "",
    };
  },

  computed: {
    ...mapGetters(["get_trading_platforms", "get_demo_trading_platforms"]),

    get_signup_data() {
      let data = this.$store.getters.get_signup_data;
      // OneTradingMarkets has added password confirm on first step (that field does not exist on backend so we need to add manualy)
      if (
        (process.env.VUE_APP_WHITELABEL === "OneTradingMarkets" ||
          process.env.VUE_APP_WHITELABEL === "VCGMarkets" ||
          process.env.VUE_APP_WHITELABEL === "ICMVC" ||
          process.env.VUE_APP_WHITELABEL === "ICMMU") &&
        this.data.step_meta &&
        !this.data.step_meta[0][0].fields.includes("password_confirmation")
      ) {
        const t = this.$t.bind(this);
        this.data.step_meta[0][0].fields.splice(5, 0, "password_confirmation");
        this.get_signup_data.actions.GET.fields.push({
          enabled: true,
          id: "password_confirmation",
          key: "password_confirmation",
          kind: "password",
          label: t("password-confirmation"),
          needed: true,
          type: "string",
        });
        return this.data;
      } else {
        return data;
      }
    },

    lang() {
      return this.$store.getters.get_lang;
    },
  },
  methods: {
    set_demo_signup_data,
    addPasswordField() {
      const passwordFieldIndex =
        this.get_signup_data.step_meta[0][0].fields.findIndex(
          (field) => field === "password",
        );

      if (passwordFieldIndex !== -1) {
        this.get_signup_data.step_meta[0][0].fields.splice(
          passwordFieldIndex + 1,
          0,
          "password_confirmation",
        );
        this.get_signup_data.actions.GET.fields.splice(
          passwordFieldIndex + 1,
          0,
          {
            enabled: true,
            id: "password_confirmation",
            key: "password_confirmation",
            kind: "password",
            label: this.$t("password-confirmation"),
            needed: true,
            type: "string",
          },
        );
      }
    },
  },
  watch: {
    lang() {
      if (
        process.env.VUE_APP_WHITELABEL === "OneTradingMarkets" ||
        (process.env.VUE_APP_WHITELABEL === "ICMVC" &&
          this.stage !== "production") ||
        (process.env.VUE_APP_WHITELABEL === "ICMMU" &&
          this.stage !== "production")
      ) {
        console.log("DATA 1");
        this.$store.dispatch("set_signup_data").then(() => {
          this.isLoading = false;
          const t = this.$t.bind(this);
        });
      } else {
        console.log("DATA 2");
        this.$store.dispatch("set_demo_signup_data").then(() => {
          this.isLoading = false;
          const t = this.$t.bind(this);
          if (
            process.env.VUE_APP_WHITELABEL !== "TD365" &&
            process.env.VUE_APP_WHITELABEL !== "VCGMarkets" &&
            this.get_signup_data
          ) {
            this.addPasswordField();
          }
        });
      }
    },
  },
  beforeCreate() {
    if (!localStorage.getItem("step")) {
      localStorage.setItem("step", "0");
    }
  },
  created() {
    this.isLoading = true;
    this.stage = process.env.VUE_APP_API_URL.includes("dev.")
      ? "dev"
      : process.env.VUE_APP_API_URL.includes("stage.")
        ? "stage"
        : "production";

    if (this.$route.query.t && !Cookie.get("x-tracking-id")) {
      Cookie.set("x-tracking-id", this.$route.query.t);
    }
    if (Cookie.get("x-tracking-id")) {
      this.$store.dispatch(
        "ib_tracking_link_visited",
        Cookie.get("x-tracking-id"),
      );
    }
    if (Cookie.get("x-tracking-id")) {
      this.$store
        .dispatch("display_trading_platforms_tracking_links")
        .then(() => {
          this.isLoading = false;
        });
    } else {
      if (process.env.VUE_APP_WHITELABEL === "TradeCoreUK") {
        this.$store
          .dispatch("display_trading_platforms_with_whitelabel")
          .then(() => {
            this.isLoading = false;
          });
      } else {
        this.$store.dispatch("display_trading_platforms").then(() => {
          this.isLoading = false;
        });
      }
    }
    if (process.env.VUE_APP_WHITELABEL === "TradeCoreUK") {
      this.$store.dispatch("display_demo_trading_platforms_with_whitelabel");
    } else {
      this.$store.dispatch("display_demo_trading_platforms");
    }

    // we check if EGM user came from IB demo tracking link, in that case we should have set 'demoLink' in coockie
    // if that info exist, we display demo form, else we display live form
    if (
      Cookie.get("demoLink") &&
      process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets"
    ) {
      this.EGMdemo = true;
      this.$store.dispatch("set_demo_signup_data").then(() => {
        this.data = this.$store.getters.get_signup_data;
        this.data.step_meta[0][0].fields =
          this.data.step_meta[0][0].fields.filter(function (e) {
            return e !== "username";
          });
        this.isLoading = false;
        const t = this.$t.bind(this);
      });
    }
    // here we display the live form
    else if (
      process.env.VUE_APP_WHITELABEL === "TD365" ||
      process.env.VUE_APP_WHITELABEL === "OneTradingMarkets" ||
      process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
      (process.env.VUE_APP_WHITELABEL === "ICMVC" &&
        this.stage !== "production") ||
      (process.env.VUE_APP_WHITELABEL === "ICMMU" &&
        this.stage !== "production")
    ) {
      this.$store.dispatch("set_signup_data").then(() => {
        this.isLoading = false;
        this.data = this.$store.getters.get_signup_data;
        // EGM client requested that we remove username filed from first step registration
        if (process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets") {
          this.data.step_meta[0][0].fields =
            this.data.step_meta[0][0].fields.filter(function (e) {
              return e !== "username";
            });
        }
      });
    }
    // here we display the live form but with whitelabel info in the header of the api request
    else if (process.env.VUE_APP_WHITELABEL === "TDSouthAfrica") {
      this.$store.dispatch("set_signup_data_with_whitelabel").then(() => {
        this.isLoading = false;
        if (this.get_signup_data) {
          this.get_signup_data.step_meta[2][0].fields.splice(
            this.get_signup_data.step_meta[2][0].fields.indexOf("currency"),
            1,
          );
        }
      });
    }
    // here we display the demo form but with whitelabel info in the header of the api request
    else if (process.env.VUE_APP_WHITELABEL === "TradeCoreUK") {
      this.$store.dispatch("set_demo_signup_data_with_whitelabel").then(() => {
        this.isLoading = false;
        const t = this.$t.bind(this);
        this.addPasswordField();
      });
    }
    // here we display the demo form
    else
      this.$store.dispatch("set_demo_signup_data").then(() => {
        this.isLoading = false;

        const t = this.$t.bind(this);
        if (
          (process.env.VUE_APP_WHITELABEL === "ICMCapital" ||
            process.env.VUE_APP_WHITELABEL === "OrbitInvest" ||
            process.env.VUE_APP_WHITELABEL === "ICMMENA") &&
          this.get_signup_data
        ) {
          this.addPasswordField();
        }
      });
  },
};
</script>
