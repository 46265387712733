<template>
  <div>
    <div class="card-vue">
      <div
        :class="showMenu ? 'open-card' : ''"
        class="bg-white card relative shadow overflow-hidden sm:rounded-md max-w-7xl sm:p-0 m-auto mt-4 relative p-4"
      >
        <ul>
          <li>
            <div
              class="card-wrapper sm:block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out py-5 px-8"
            >
              <div class="flex items-center">
                <div class="min-w-0 flex-1 sm:flex sm:items-center">
                  <div class="mr-5">
                    <div
                      v-if="whitelabel === 'EagleGlobalMarkets'"
                      class="text-sm leading-5 font-medium text-indigo-600 truncate absolute sm:static top-6 left-6"
                    >
                      <img
                        :src="
                          require(`../../assets/EagleGlobalMarkets-logo.svg`)
                        "
                        alt=""
                        class="md:block h-auto w-20"
                      />
                    </div>
                    <div
                      v-else-if="
                        data.backend.name === 'Cloud-SingleCurrencyTrading'
                      "
                      class="text-sm leading-5 font-medium text-indigo-600 truncate absolute sm:static top-6 left-6"
                    >
                      <img
                        :src="
                          require(`../../assets/icon-${data.backend.name}.png`)
                        "
                        alt=""
                      />
                    </div>
                    <div
                      v-else
                      class="text-sm leading-5 font-medium text-indigo-600 truncate absolute sm:static top-6 left-6"
                    >
                      <img
                        :src="
                          require(`../../assets/icon-${data.backend.type}.png`)
                        "
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="ms-5 mb-2 w-32">
                    <div
                      class="text-sm leading-5 font-medium text-indigo-600 truncate absolute sm:static top-4 right-4"
                    >
                      <div v-if="data.backend.mode === 'Demo'">
                        <svg
                          fill="none"
                          height="20"
                          viewBox="0 0 46 20"
                          width="46"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            class="card-demo-pill-background"
                            fill="#6554C0"
                            height="19"
                            rx="2.5"
                            width="45"
                            x="0.5"
                            y="0.5"
                          />
                          <path
                            d="M9.81818 14C12.4773 14 14.054 12.3551 14.054 9.62784C14.054 6.90909 12.4773 5.27273 9.87784 5.27273H6.8608V14H9.81818ZM8.44176 12.6321V6.64062H9.78835C11.5611 6.64062 12.4858 7.62926 12.4858 9.62784C12.4858 11.6349 11.5611 12.6321 9.74148 12.6321H8.44176ZM18.3857 14.1278C19.9112 14.1278 20.9595 13.3821 21.2322 12.2443L19.7919 12.0824C19.5831 12.6364 19.0717 12.9261 18.407 12.9261C17.4098 12.9261 16.7493 12.2699 16.7365 11.1491H21.2962V10.6761C21.2962 8.37926 19.9155 7.36932 18.3047 7.36932C16.4297 7.36932 15.2067 8.74574 15.2067 10.7656C15.2067 12.8196 16.4126 14.1278 18.3857 14.1278ZM16.7408 10.1094C16.7876 9.27415 17.4055 8.57102 18.326 8.57102C19.2124 8.57102 19.8089 9.21875 19.8175 10.1094H16.7408ZM22.6012 14H24.1438V10.0199C24.1438 9.21449 24.6808 8.66903 25.3455 8.66903C25.9975 8.66903 26.445 9.10795 26.445 9.78125V14H27.9577V9.91761C27.9577 9.1804 28.3967 8.66903 29.1424 8.66903C29.7646 8.66903 30.2589 9.03551 30.2589 9.84517V14H31.8058V9.60653C31.8058 8.14489 30.962 7.36932 29.7603 7.36932C28.81 7.36932 28.0856 7.83807 27.7958 8.56676H27.7276C27.4762 7.82528 26.8413 7.36932 25.9592 7.36932C25.0813 7.36932 24.4251 7.82102 24.1523 8.56676H24.0756V7.45455H22.6012V14ZM36.2259 14.1278C38.1435 14.1278 39.3622 12.777 39.3622 10.7528C39.3622 8.72443 38.1435 7.36932 36.2259 7.36932C34.3082 7.36932 33.0895 8.72443 33.0895 10.7528C33.0895 12.777 34.3082 14.1278 36.2259 14.1278ZM36.2344 12.892C35.1733 12.892 34.6534 11.946 34.6534 10.7486C34.6534 9.55114 35.1733 8.59233 36.2344 8.59233C37.2784 8.59233 37.7983 9.55114 37.7983 10.7486C37.7983 11.946 37.2784 12.892 36.2344 12.892Z"
                            fill="white"
                          />
                          <rect
                            class="card-demo-pill-border"
                            height="19"
                            rx="2.5"
                            stroke="#403294"
                            width="45"
                            x="0.5"
                            y="0.5"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      whitelabel === 'TD365' ||
                      whitelabel === 'TDSouthAfrica' ||
                      whitelabel === 'Blackstone' ||
                      whitelabel === 'EagleGlobalMarkets' ||
                      whitelabel === 'TradeCoreUK'
                    "
                    class="mr-5 w-50 card-right"
                  >
                    <div
                      class="truncate text-gray-400 text-xs font-normal hidden"
                    >
                      Account
                    </div>
                    <div
                      v-if="data.meta.Alphacode"
                      class="text-sm leading-5 font-normal text-gray-400 truncate"
                    >
                      {{ data.meta.Alphacode }}
                    </div>
                    <div
                      v-else-if="data.external_id"
                      class="text-sm leading-5 font-normal text-gray-400 truncate"
                    >
                      {{ data.external_id }}
                    </div>
                    <div
                      v-else
                      class="text-sm leading-5 font-normal text-gray-400 truncate word-break"
                    >
                      Account not ready yet
                    </div>
                  </div>
                  <div v-else class="mr-5 w-32 card-right">
                    <div
                      class="truncate text-gray-400 text-xs font-normal hidden"
                    >
                      {{ $t("account-id") }}
                    </div>
                    <div
                      class="text-sm leading-5 font-normal text-gray-400 truncate"
                    >
                      {{ data.external_id }}
                    </div>
                  </div>
                  <div class="mr-5 w-32 card-left">
                    <div
                      class="truncate text-gray-400 text-xs font-normal hidden"
                    >
                      {{ $t("Currency") }}
                    </div>
                    <div
                      class="text-sm leading-5 font-normal text-gray-400 truncate"
                    >
                      {{ data.currency }}
                    </div>
                  </div>
                  <div v-if="whitelabel.includes('ICM')" class="mr-5">
                    <div
                      class="text-sm leading-5 font-normal text-gray-400 truncate"
                    >
                      <span>{{ $t("Leverage") }}:</span>
                      {{ data.leverage }}
                    </div>
                  </div>

                  <div class="mt-4 flex-shrink-0 sm:mt-0 flex-1">
                    <div
                      class="flex overflow-hidden float-right absolute sm:static bottom-0 left-0 button-bottom"
                    >
                      <a
                        v-if="
                          data.backend.type === 'MT4' &&
                          whitelabel !== 'Blackstone' &&
                          whitelabel !== 'OneTradingMarkets'
                        "
                        :href="urlMT4"
                      >
                        <button
                          class="bg-main-button-color float-left relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-white focus:outline-none"
                          type="button"
                        >
                          <svg
                            class="hidden"
                            fill="none"
                            height="15"
                            viewBox="0 0 16 15"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.4375 8.625H11.8945L13.1797 7.36719C14 6.54688 13.3984 5.125 12.25 5.125H10.5V2.0625C10.5 1.35156 9.89844 0.75 9.1875 0.75H6.5625C5.82422 0.75 5.25 1.35156 5.25 2.0625V5.125H3.5C2.32422 5.125 1.72266 6.54688 2.57031 7.36719L3.82812 8.625H1.3125C0.574219 8.625 0 9.22656 0 9.9375V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H14.4375C15.1484 14.75 15.75 14.1758 15.75 13.4375V9.9375C15.75 9.22656 15.1484 8.625 14.4375 8.625ZM3.5 6.4375H6.5625V2.0625H9.1875V6.4375H12.25L7.875 10.8125L3.5 6.4375ZM14.4375 13.4375H1.3125V9.9375H5.14062L6.94531 11.7422C7.4375 12.2617 8.28516 12.2617 8.77734 11.7422L10.582 9.9375H14.4375V13.4375ZM12.0312 11.6875C12.0312 12.0703 12.3047 12.3438 12.6875 12.3438C13.043 12.3438 13.3438 12.0703 13.3438 11.6875C13.3438 11.332 13.043 11.0312 12.6875 11.0312C12.3047 11.0312 12.0312 11.332 12.0312 11.6875Z"
                              fill="#6C727F"
                            />
                          </svg>
                          {{ $t("Download") }}
                        </button>
                      </a>
                      <a
                        v-if="
                          data.backend.type === 'MT5' &&
                          whitelabel !== 'Blackstone'
                        "
                        :href="urlMT5"
                      >
                        <button
                          class="bg-main-button-color float-left relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-white focus:outline-none"
                          type="button"
                        >
                          <svg
                            class="hidden"
                            fill="none"
                            height="15"
                            viewBox="0 0 16 15"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.4375 8.625H11.8945L13.1797 7.36719C14 6.54688 13.3984 5.125 12.25 5.125H10.5V2.0625C10.5 1.35156 9.89844 0.75 9.1875 0.75H6.5625C5.82422 0.75 5.25 1.35156 5.25 2.0625V5.125H3.5C2.32422 5.125 1.72266 6.54688 2.57031 7.36719L3.82812 8.625H1.3125C0.574219 8.625 0 9.22656 0 9.9375V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H14.4375C15.1484 14.75 15.75 14.1758 15.75 13.4375V9.9375C15.75 9.22656 15.1484 8.625 14.4375 8.625ZM3.5 6.4375H6.5625V2.0625H9.1875V6.4375H12.25L7.875 10.8125L3.5 6.4375ZM14.4375 13.4375H1.3125V9.9375H5.14062L6.94531 11.7422C7.4375 12.2617 8.28516 12.2617 8.77734 11.7422L10.582 9.9375H14.4375V13.4375ZM12.0312 11.6875C12.0312 12.0703 12.3047 12.3438 12.6875 12.3438C13.043 12.3438 13.3438 12.0703 13.3438 11.6875C13.3438 11.332 13.043 11.0312 12.6875 11.0312C12.3047 11.0312 12.0312 11.332 12.0312 11.6875Z"
                              fill="#6C727F"
                            />
                          </svg>
                          {{ $t("Download") }}
                        </button>
                      </a>
                      <a
                        v-if="
                          data.backend.type === 'CTrader' &&
                          (whitelabel === 'ICMVC' ||
                            whitelabel === 'ICMCapital' ||
                            whitelabel === 'ICMMENA')
                        "
                        :href="urlCTrader"
                      >
                        <button
                          class="bg-main-button-color float-left relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-white focus:outline-none"
                          type="button"
                        >
                          <svg
                            class="hidden"
                            fill="none"
                            height="15"
                            viewBox="0 0 16 15"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.4375 8.625H11.8945L13.1797 7.36719C14 6.54688 13.3984 5.125 12.25 5.125H10.5V2.0625C10.5 1.35156 9.89844 0.75 9.1875 0.75H6.5625C5.82422 0.75 5.25 1.35156 5.25 2.0625V5.125H3.5C2.32422 5.125 1.72266 6.54688 2.57031 7.36719L3.82812 8.625H1.3125C0.574219 8.625 0 9.22656 0 9.9375V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H14.4375C15.1484 14.75 15.75 14.1758 15.75 13.4375V9.9375C15.75 9.22656 15.1484 8.625 14.4375 8.625ZM3.5 6.4375H6.5625V2.0625H9.1875V6.4375H12.25L7.875 10.8125L3.5 6.4375ZM14.4375 13.4375H1.3125V9.9375H5.14062L6.94531 11.7422C7.4375 12.2617 8.28516 12.2617 8.77734 11.7422L10.582 9.9375H14.4375V13.4375ZM12.0312 11.6875C12.0312 12.0703 12.3047 12.3438 12.6875 12.3438C13.043 12.3438 13.3438 12.0703 13.3438 11.6875C13.3438 11.332 13.043 11.0312 12.6875 11.0312C12.3047 11.0312 12.0312 11.332 12.0312 11.6875Z"
                              fill="#6C727F"
                            />
                          </svg>
                          {{ $t("Download") }}
                        </button>
                      </a>
                      <a
                        v-if="
                          data.backend.type === 'CTrader' &&
                          whitelabel === 'ICMMU'
                        "
                        :href="icmmuCTrader"
                      >
                        <button
                          class="bg-main-button-color float-left relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-white focus:outline-none"
                          type="button"
                        >
                          <svg
                            class="hidden"
                            fill="none"
                            height="15"
                            viewBox="0 0 16 15"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.4375 8.625H11.8945L13.1797 7.36719C14 6.54688 13.3984 5.125 12.25 5.125H10.5V2.0625C10.5 1.35156 9.89844 0.75 9.1875 0.75H6.5625C5.82422 0.75 5.25 1.35156 5.25 2.0625V5.125H3.5C2.32422 5.125 1.72266 6.54688 2.57031 7.36719L3.82812 8.625H1.3125C0.574219 8.625 0 9.22656 0 9.9375V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H14.4375C15.1484 14.75 15.75 14.1758 15.75 13.4375V9.9375C15.75 9.22656 15.1484 8.625 14.4375 8.625ZM3.5 6.4375H6.5625V2.0625H9.1875V6.4375H12.25L7.875 10.8125L3.5 6.4375ZM14.4375 13.4375H1.3125V9.9375H5.14062L6.94531 11.7422C7.4375 12.2617 8.28516 12.2617 8.77734 11.7422L10.582 9.9375H14.4375V13.4375ZM12.0312 11.6875C12.0312 12.0703 12.3047 12.3438 12.6875 12.3438C13.043 12.3438 13.3438 12.0703 13.3438 11.6875C13.3438 11.332 13.043 11.0312 12.6875 11.0312C12.3047 11.0312 12.0312 11.332 12.0312 11.6875Z"
                              fill="#6C727F"
                            />
                          </svg>
                          {{ $t("Download") }}
                        </button>
                      </a>
                      <a
                        v-if="
                          data.backend.type === 'CTrader' &&
                          (whitelabel === 'GCCBrokers' ||
                            whitelabel === 'TradiNext')
                        "
                        :href="urlCTrader"
                      >
                        <button
                          class="bg-main-button-color float-left relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-white focus:outline-none"
                          type="button"
                        >
                          <svg
                            class="hidden"
                            fill="none"
                            height="15"
                            viewBox="0 0 16 15"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.4375 8.625H11.8945L13.1797 7.36719C14 6.54688 13.3984 5.125 12.25 5.125H10.5V2.0625C10.5 1.35156 9.89844 0.75 9.1875 0.75H6.5625C5.82422 0.75 5.25 1.35156 5.25 2.0625V5.125H3.5C2.32422 5.125 1.72266 6.54688 2.57031 7.36719L3.82812 8.625H1.3125C0.574219 8.625 0 9.22656 0 9.9375V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H14.4375C15.1484 14.75 15.75 14.1758 15.75 13.4375V9.9375C15.75 9.22656 15.1484 8.625 14.4375 8.625ZM3.5 6.4375H6.5625V2.0625H9.1875V6.4375H12.25L7.875 10.8125L3.5 6.4375ZM14.4375 13.4375H1.3125V9.9375H5.14062L6.94531 11.7422C7.4375 12.2617 8.28516 12.2617 8.77734 11.7422L10.582 9.9375H14.4375V13.4375ZM12.0312 11.6875C12.0312 12.0703 12.3047 12.3438 12.6875 12.3438C13.043 12.3438 13.3438 12.0703 13.3438 11.6875C13.3438 11.332 13.043 11.0312 12.6875 11.0312C12.3047 11.0312 12.0312 11.332 12.0312 11.6875Z"
                              fill="#6C727F"
                            />
                          </svg>
                          {{ $t("Download") }}
                        </button>
                      </a>
                      <template
                        v-if="
                          data.backend.type === 'CloudTrade' &&
                          whitelabel === 'Blackstone'
                        "
                      >
                        <div class="w-full sm:ml-0 ml-6">
                          <form
                            id="ct-launch-form"
                            action="https://cloudtradebeta.finsa.com.au/finlogin/loginws.aspx"
                            class="ng-pristine ng-valid ng-scope"
                            method="post"
                            target="_blank"
                          >
                            <input
                              name="username"
                              type="hidden"
                              value="blackstone4_demo"
                            />
                            <input
                              name="password"
                              type="hidden"
                              value="Marko123"
                            />
                            <button
                              class="cursor-pointer outline-none focus:outline-none w-100"
                              style="padding: 0"
                              type="submit"
                            >
                              <img
                                :src="require(`../../assets/windows_icon.svg`)"
                                alt=""
                                class="w-8 mr-3 sm:mt-0 mt-2"
                              />
                            </button>
                          </form>
                        </div>
                        <a
                          class="w-full"
                          href="https://itunes.apple.com/za/app/cloudtrade/id1022781350?mt=8"
                          target="_blank"
                        >
                          <img
                            :src="require(`../../assets/apple_icon.svg`)"
                            alt=""
                            class="w-8 mr-3 sm:mt-0 mt-2"
                          />
                        </a>
                        <a
                          class="w-full"
                          href="https://play.google.com/store/apps/details?id=com.finsa.finsamobile"
                          target="_blank"
                        >
                          <img
                            :src="require(`../../assets/android_icon.svg`)"
                            alt=""
                            class="w-8 sm:mt-0 mt-2"
                          />
                        </a>
                      </template>
                      <template
                        v-if="
                          data.backend.type === 'MT4' &&
                          whitelabel === 'OneTradingMarkets'
                        "
                      >
                        <a
                          class="w-full"
                          href="https://download.mql5.com/cdn/web/14241/mt4/otmarkets4setup.exe"
                          target="_blank"
                        >
                          <img
                            :src="require(`../../assets/windows_icon.svg`)"
                            alt=""
                            class="w-8 mr-3 sm:mt-0 mt-2"
                          />
                        </a>
                        <a
                          class="w-full"
                          href="https://download.mql5.com/cdn/mobile/mt4/ios?server=OTMarkets-Demo,OTMarkets-LIVE,OTMarkets-LIVE2"
                          target="_blank"
                        >
                          <img
                            :src="require(`../../assets/apple_icon.svg`)"
                            alt=""
                            class="w-8 mr-3 sm:mt-0 mt-2"
                          />
                        </a>
                        <a
                          class="w-full"
                          href="https://download.mql5.com/cdn/mobile/mt4/android?serv"
                          target="_blank"
                        >
                          <img
                            :src="require(`../../assets/android_icon.svg`)"
                            alt=""
                            class="w-8 sm:mt-0 mt-2"
                          />
                        </a>
                      </template>
                      <template
                        v-if="
                          (whitelabel === 'TD365' ||
                            whitelabel === 'TDSouthAfrica') &&
                          data.backend.type === 'CloudTrade'
                        "
                      >
                        <form
                          :action="data.login_url"
                          method="post"
                          target="_blank"
                        >
                          <input
                            :value="data.username"
                            name="username"
                            type="hidden"
                          />
                          <input
                            :value="get_user_data.password"
                            name="password"
                            type="hidden"
                          />
                          <button
                            class="bg-main-button-color float-left relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-white focus:outline-none"
                            type="submit"
                          >
                            <svg
                              class="hidden"
                              fill="none"
                              height="15"
                              viewBox="0 0 16 15"
                              width="16"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.4375 8.625H11.8945L13.1797 7.36719C14 6.54688 13.3984 5.125 12.25 5.125H10.5V2.0625C10.5 1.35156 9.89844 0.75 9.1875 0.75H6.5625C5.82422 0.75 5.25 1.35156 5.25 2.0625V5.125H3.5C2.32422 5.125 1.72266 6.54688 2.57031 7.36719L3.82812 8.625H1.3125C0.574219 8.625 0 9.22656 0 9.9375V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H14.4375C15.1484 14.75 15.75 14.1758 15.75 13.4375V9.9375C15.75 9.22656 15.1484 8.625 14.4375 8.625ZM3.5 6.4375H6.5625V2.0625H9.1875V6.4375H12.25L7.875 10.8125L3.5 6.4375ZM14.4375 13.4375H1.3125V9.9375H5.14062L6.94531 11.7422C7.4375 12.2617 8.28516 12.2617 8.77734 11.7422L10.582 9.9375H14.4375V13.4375ZM12.0312 11.6875C12.0312 12.0703 12.3047 12.3438 12.6875 12.3438C13.043 12.3438 13.3438 12.0703 13.3438 11.6875C13.3438 11.332 13.043 11.0312 12.6875 11.0312C12.3047 11.0312 12.0312 11.332 12.0312 11.6875Z"
                                fill="#6C727F"
                              />
                            </svg>
                            <span>Launch</span>
                          </button>
                        </form>
                      </template>
                      <template
                        v-if="
                          (whitelabel === 'EagleGlobalMarkets' ||
                            whitelabel === 'TradeCoreUK') &&
                          data.backend.type === 'DXTrade'
                        "
                      >
                        <!-- <form action="https://eagletrade.eagleglobalmarkets.com/" 
                          method="post" target="_blank">
                          <input type="hidden" name="username" :value="data.username">
                          <input type="hidden" name="password" :value="get_user_data.password">
                          <button type="submit" class="bg-main-button-color float-left relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-white focus:outline-none">
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="hidden">
                              <path
                                  d="M14.4375 8.625H11.8945L13.1797 7.36719C14 6.54688 13.3984 5.125 12.25 5.125H10.5V2.0625C10.5 1.35156 9.89844 0.75 9.1875 0.75H6.5625C5.82422 0.75 5.25 1.35156 5.25 2.0625V5.125H3.5C2.32422 5.125 1.72266 6.54688 2.57031 7.36719L3.82812 8.625H1.3125C0.574219 8.625 0 9.22656 0 9.9375V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H14.4375C15.1484 14.75 15.75 14.1758 15.75 13.4375V9.9375C15.75 9.22656 15.1484 8.625 14.4375 8.625ZM3.5 6.4375H6.5625V2.0625H9.1875V6.4375H12.25L7.875 10.8125L3.5 6.4375ZM14.4375 13.4375H1.3125V9.9375H5.14062L6.94531 11.7422C7.4375 12.2617 8.28516 12.2617 8.77734 11.7422L10.582 9.9375H14.4375V13.4375ZM12.0312 11.6875C12.0312 12.0703 12.3047 12.3438 12.6875 12.3438C13.043 12.3438 13.3438 12.0703 13.3438 11.6875C13.3438 11.332 13.043 11.0312 12.6875 11.0312C12.3047 11.0312 12.0312 11.332 12.0312 11.6875Z"
                                  fill="#6C727F"/>
                            </svg>
                            <span>Launch</span>
                          </button>
                        </form> -->

                        <button
                          class="bg-main-button-color float-left relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-white bg-white focus:outline-none"
                          @click="getPlatformUrl(data.external_id)"
                        >
                          <svg
                            class="hidden"
                            fill="none"
                            height="15"
                            viewBox="0 0 16 15"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.4375 8.625H11.8945L13.1797 7.36719C14 6.54688 13.3984 5.125 12.25 5.125H10.5V2.0625C10.5 1.35156 9.89844 0.75 9.1875 0.75H6.5625C5.82422 0.75 5.25 1.35156 5.25 2.0625V5.125H3.5C2.32422 5.125 1.72266 6.54688 2.57031 7.36719L3.82812 8.625H1.3125C0.574219 8.625 0 9.22656 0 9.9375V13.4375C0 14.1758 0.574219 14.75 1.3125 14.75H14.4375C15.1484 14.75 15.75 14.1758 15.75 13.4375V9.9375C15.75 9.22656 15.1484 8.625 14.4375 8.625ZM3.5 6.4375H6.5625V2.0625H9.1875V6.4375H12.25L7.875 10.8125L3.5 6.4375ZM14.4375 13.4375H1.3125V9.9375H5.14062L6.94531 11.7422C7.4375 12.2617 8.28516 12.2617 8.77734 11.7422L10.582 9.9375H14.4375V13.4375ZM12.0312 11.6875C12.0312 12.0703 12.3047 12.3438 12.6875 12.3438C13.043 12.3438 13.3438 12.0703 13.3438 11.6875C13.3438 11.332 13.043 11.0312 12.6875 11.0312C12.3047 11.0312 12.0312 11.332 12.0312 11.6875Z"
                              fill="#6C727F"
                            />
                          </svg>
                          <span>Launch</span>
                        </button>
                      </template>
                    </div>
                  </div>
                </div>
                <div
                  class="ml-5 flex-shrink-0 absolute sm:static bottom-0 right-0 button-bottom-right cursor-pointer"
                >
                  <div class="hidden title" @click="showMenu = !showMenu">
                    <div class="mr-3">
                      {{ $t("more-info") }}
                    </div>
                    <svg
                      class="my-auto"
                      fill="none"
                      height="5"
                      viewBox="0 0 9 5"
                      width="9"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.847656 0C0.355469 0 0.109375 0.601562 0.464844 0.957031L3.96484 4.45703C4.18359 4.67578 4.53906 4.67578 4.75781 4.45703L8.25781 0.957031C8.61328 0.601562 8.36719 0 7.875 0H0.847656Z"
                        fill="#6C727F"
                      />
                    </svg>
                  </div>
                  <div
                    class="hidden sm:flex cursor-pointer"
                    @click="showMenu = !showMenu"
                  >
                    <svg
                      v-if="showMenu"
                      fill="none"
                      height="42"
                      viewBox="0 0 42 42"
                      width="42"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d)">
                        <rect
                          fill="white"
                          height="38"
                          rx="6"
                          width="38"
                          x="2"
                          y="1"
                        />
                        <path
                          d="M24.5 22.375C24.9922 22.375 25.2383 21.8008 24.8828 21.4453L21.3828 17.9453C21.1641 17.7266 20.8086 17.7266 20.5898 17.9453L17.0898 21.4453C16.7344 21.8008 16.9805 22.375 17.4727 22.375H24.5Z"
                          fill="#374151"
                        />
                        <rect
                          height="37"
                          rx="5.5"
                          stroke="#D2D6DC"
                          width="37"
                          x="2.5"
                          y="1.5"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d"
                          color-interpolation-filters="sRGB"
                          filterUnits="userSpaceOnUse"
                          height="42"
                          width="42"
                          x="0"
                          y="0"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          />
                          <feOffset dy="1" />
                          <feGaussianBlur stdDeviation="1" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                          />
                          <feBlend
                            in2="BackgroundImageFix"
                            mode="normal"
                            result="effect1_dropShadow"
                          />
                          <feBlend
                            in="SourceGraphic"
                            in2="effect1_dropShadow"
                            mode="normal"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                    <svg
                      v-else
                      fill="none"
                      height="42"
                      viewBox="0 0 42 42"
                      width="42"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d)">
                        <rect
                          fill="white"
                          height="38"
                          rx="6"
                          width="38"
                          x="2"
                          y="1"
                        />
                        <path
                          d="M17.4727 18C16.9805 18 16.7344 18.6016 17.0898 18.957L20.5898 22.457C20.8086 22.6758 21.1641 22.6758 21.3828 22.457L24.8828 18.957C25.2383 18.6016 24.9922 18 24.5 18H17.4727Z"
                          fill="#374151"
                        />
                        <rect
                          height="37"
                          rx="5.5"
                          stroke="#D2D6DC"
                          width="37"
                          x="2.5"
                          y="1.5"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d"
                          color-interpolation-filters="sRGB"
                          filterUnits="userSpaceOnUse"
                          height="42"
                          width="42"
                          x="0"
                          y="0"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          />
                          <feOffset dy="1" />
                          <feGaussianBlur stdDeviation="1" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                          />
                          <feBlend
                            in2="BackgroundImageFix"
                            mode="normal"
                            result="effect1_dropShadow"
                          />
                          <feBlend
                            in="SourceGraphic"
                            in2="effect1_dropShadow"
                            mode="normal"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li
            v-if="showMenu"
            class="border-t text-sm text-gray-600 grid-cols-2 col-gap-10 sm:grid py-5 px-8"
          >
            <div
              v-if="
                whitelabel === 'TD365' ||
                whitelabel === 'TDSouthAfrica' ||
                whitelabel === 'Blackstone' ||
                whitelabel === 'OneTradingMarkets' ||
                whitelabel === 'EagleGlobalMarkets' ||
                whitelabel === 'TradeCoreUK'
              "
            >
              <div class="border-b w-full flex justify-between py-3">
                <div class="info-field-name">
                  {{ $t("Balance") }}
                </div>
                <div v-if="data.balance.CashBalance !== 'n/a'">
                  <money-format
                    :currency-code="data.currency"
                    :value="data.balance.CashBalance"
                  >
                  </money-format>
                </div>
              </div>
            </div>

            <div v-else>
              <div class="border-b w-full flex justify-between py-3">
                <div class="info-field-name">
                  {{ $t("free-margin") }}
                </div>
                <div v-if="data.balance.CashBalance !== 'n/a'">
                  <money-format
                    :currency-code="data.currency"
                    :value="data.balance.CashBalance"
                  >
                  </money-format>
                </div>
              </div>
              <div class="border-b w-full flex justify-between py-3">
                <div class="info-field-name">
                  {{ $t("Credit") }}
                </div>
                <div v-if="data.balance.CashBalance !== 'n/a'">
                  <money-format
                    :currency-code="data.currency"
                    :value="data.balance.TotalCredit"
                  >
                  </money-format>
                </div>
              </div>
              <div class="border-b w-full flex justify-between py-3">
                <div class="info-field-name">
                  {{ $t("Equity") }}
                </div>
                <div v-if="data.balance.CashBalance !== 'n/a'">
                  <money-format
                    :currency-code="data.currency"
                    :value="data.balance.TotalEquity"
                  >
                  </money-format>
                </div>
              </div>
            </div>

            <div
              v-if="
                whitelabel === 'TD365' ||
                whitelabel === 'TDSouthAfrica' ||
                whitelabel === 'Blackstone' ||
                whitelabel === 'OneTradingMarkets' ||
                whitelabel === 'EagleGlobalMarkets' ||
                whitelabel === 'TradeCoreUK'
              "
            >
              <div class="border-b w-full flex justify-between py-3">
                <div class="info-field-name">
                  {{ $t("Equity") }}
                </div>
                <div v-if="data.balance.CashBalance !== 'n/a'">
                  <money-format
                    :currency-code="data.currency"
                    :value="data.balance.TotalEquity"
                  >
                  </money-format>
                </div>
              </div>
            </div>

            <div v-else>
              <div class="border-b w-full flex justify-between py-3">
                <div class="info-field-name">
                  {{ $t("Margin") }}
                </div>
                <div v-if="data.backend.type === 'MT4'">
                  <money-format
                    :currency-code="data.currency"
                    :value="data.balance.Margin"
                  >
                  </money-format>
                </div>
                <div v-else>
                  <money-format
                    :currency-code="data.currency ? data.currency : 'USD'"
                    :value="
                      data.balance.UsedMargin ? data.balance.UsedMargin : 0
                    "
                  >
                  </money-format>
                </div>
              </div>
              <div class="border-b w-full flex justify-between py-3">
                <div class="info-field-name">
                  {{ $t("margin-level") }}
                </div>
                <div v-if="isNaN(data.balance.Level)">
                  {{ data.balance.Level }}
                </div>
                <div v-else>{{ data.balance.Level.toFixed(2) }}%</div>
              </div>
              <div class="border-b w-full flex justify-between py-3">
                <div class="info-field-name">
                  {{ $t("p-and-l") }}
                </div>
                <div>
                  <money-format
                    :currency-code="data.currency ? data.currency : 'USD'"
                    :value="data.balance.PnL ? data.balance.PnL : 0"
                  >
                  </money-format>
                </div>
              </div>
            </div>

            <div
              v-if="
                (whitelabel === 'TD365' ||
                  whitelabel === 'TDSouthAfrica' ||
                  whitelabel === 'Blackstone' ||
                  whitelabel === 'OneTradingMarkets' ||
                  whitelabel === 'EagleGlobalMarkets' ||
                  whitelabel === 'TradeCoreUK') &&
                data.backend.mode === 'Live'
              "
              class="mt-8"
            >
              <span class="inline-flex rounded-md me-3">
                <router-link
                  class="flex items-center justify-center"
                  to="/deposit-funds"
                >
                  <button
                    class="-top-5 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                    type="button"
                    @click="depositTo(data.id)"
                  >
                    {{ $t("Deposit") }}
                  </button>
                </router-link>
              </span>
            </div>

            <div
              v-if="
                data.backend.type === 'MT4' &&
                whitelabel !== 'TD365' &&
                whitelabel !== 'TDSouthAfrica' &&
                whitelabel !== 'OneTradingMarkets'
              "
              class="mt-8"
            >
              <span class="inline-flex rounded-md me-3">
                <button
                  class="-top-5 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                  type="button"
                  @click="showChangePasswordModal = true"
                >
                  <span v-if="data.should_set_initial_password">
                    {{ $t("set-password") }}
                  </span>
                  <span v-else>
                    {{ $t("change-password-0b39c5aca15b84b1ad53a94d6b3feb78") }}
                  </span>
                </button>
              </span>
              <span class="inline-flex rounded-md">
                <button
                  class="-top-5 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
                  type="button"
                  @click="showResetPasswordModal = true"
                >
                  {{ $t("reset-password") }}
                </button>
              </span>
            </div>
          </li>
        </ul>
        <reset-password
          v-if="showResetPasswordModal"
          :id="data.id"
          @close="close"
        ></reset-password>
        <change-password
          v-if="showChangePasswordModal"
          :id="data.id"
          :accountPassword="true"
          :data="data"
          @close="close"
        ></change-password>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MoneyFormat from "vue-money-format";
import ChangePassword from "@/views/documents/profile-details/ChangePassword";
import ResetPassword from "@/views/documents/profile-details/ResetPassword";

export default {
  name: "Card",
  components: {
    "money-format": MoneyFormat,
    ChangePassword,
    ResetPassword,
  },
  data() {
    return {
      showMenu: false,
      showChangePasswordModal: false,
      showResetPasswordModal: false,
      urlMT4: null,
      urlMT5: null,
      urlCTrader: null,
      icmmuCTrader: null,
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    ...mapGetters(["get_user_data"]),
  },
  created() {
    this.urlMT5 =
      "https://download.mql5.com/cdn/web/icm.capital.ltd/mt5/icmcapital5setup.exe";
    this.urlMT4 = "https://demo.tradecore.io/static/metatrader/mt4setup.exe";
    this.urlCTrader =
      "https://icmtrader.ctrader.com/ctrader-icmtrader-setup.exe";
    this.icmmuCTrader =
      "https://getctrader.com/icmcom/ctrader-icmcom-setup.exe";

    if (this.whitelabel === "ICMMU" || this.whitelabel === "ICMMENA") {
      this.urlMT4 =
        "https://download.mql5.com/cdn/web/icm.capital.ltd/mt4/icmcapital4setup.exe";
      this.urlMT5 =
        "https://download.mql5.com/cdn/web/icm.capital.ltd/mt5/icmcapital5setup.exe";
    }
    if (this.whitelabel === "VCGMarkets") {
      this.urlMT5 =
        "https://download.mql5.com/cdn/web/vcg.markets.ltd/mt5/vcgmarkets5setup.exe";
    }
    if (this.whitelabel === "ICMAP")
      this.urlMT4 =
        "https://download.mql5.com/cdn/web/icm.capital.ltd/mt4/icmcapital4setup.exe";
    if (this.whitelabel === "ICMVC")
      this.urlMT4 =
        "https://download.mql5.com/cdn/web/13910/mt4/icmcapitalvc4setup.exe";
    if (this.whitelabel === "ICMTrader")
      this.urlMT4 =
        "https://download.mql5.com/cdn/web/18178/mt4/icmtrader4setup.exe";
    if (this.whitelabel === "ICMCapital")
      this.urlMT4 =
        "https://download.mql5.com/cdn/web/icm.capital.limited/mt4/icmcapitaluk4setup.exe";
    if (this.whitelabel === "ICMEU")
      this.urlMT4 =
        "https://download.mql5.com/cdn/web/13909/mt4/icmeurope4setup.exe";

    if (this.whitelabel === "TDSouthAfrica")
      this.urlMT4 =
        "https://download.mql5.com/cdn/web/finsa.pty.ltd/mt4/finsapty4setup.exe";
    if (this.whitelabel === "TD365")
      this.urlMT4 =
        "https://download.mql5.com/cdn/web/finsa.pty.ltd/mt4/finsapty4setup.exe";
    if (this.whitelabel === "OneTradingMarkets") {
      this.urlMT5 =
        "https://download.mql5.com/cdn/web/ot.markets.limited/mt5/otmarkets5setup.exe";
    }
    if (this.whitelabel === "EagleGlobalMarkets") {
      this.urlMT4 = "https://www.egmarkets.trade/eaglegm4setup.exe";
    }
    if (this.whitelabel === "GCCBrokers") {
      this.urlMT5 =
        "https://download.mql5.com/cdn/web/gcc.brokers.ltd/mt5/gccbrokersltd5setup.exe";
    }
    if (this.whitelabel === "GCCBrokers") {
      this.urlCTrader = "......";
    }
    if (this.whitelabel === "TradiNext") {
      this.urlMT5 = "......";
    }
    if (this.whitelabel === "TradiNext") {
      this.urlCTrader = "......";
    }
    if (this.whitelabel === "TradeCoreUK") {
      this.urlCTrader = "......";
    }
  },
  methods: {
    close() {
      this.showChangePasswordModal = false;
      this.showResetPasswordModal = false;
    },
    depositTo(accountID) {
      this.$store.commit("SET_SELECTED_ACCOUT_ID", accountID);
    },
    getPlatformUrl(id) {
      this.$store
        .dispatch("platform_url", id)
        .then((res) => {
          let link = this.$store.getters.get_mobile_login_link;
          window.location.href = link;
        })
        .catch((err) => {
          const t = this.$t.bind(this);
          this.$notify({
            group: "foo",
            text: `error`,
            type: "warn",
          });
        });
    },
  },
};
</script>
