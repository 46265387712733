var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.getters.get_profile.state === 'Demo')?_c('open-live-account'):_vm._e(),(_vm.$store.getters.get_profile.state === 'Pending')?_c('div',[_c('Header',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.$t("add-a-new-trading-account"))+" ")])]},proxy:true}],null,false,2517630884)}),_c('complete-id-identification',{attrs:{"title":""}})],1):_vm._e(),(_vm.$store.getters.get_profile.state === 'Full')?_c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addAccount)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('Header',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.whitelabel === 'Blackstone')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("add-a-new-trading-account"))+" ")]):_vm._e(),_c('p',{staticClass:"mt-1 max-w-2xl text-sm font-light text-gray-500"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( "choose-your-desired-trading-platform-and-currency-settings" ))+" ")])])]},proxy:true}],null,true)}),_c('div',{staticClass:"bg-white shadow rounded-md min-h-screen max-w-3xl m-auto sm:mt-5"},[_c('div',[_c('div',{staticClass:"sm:px-0 pt-8"},[(_vm.$store.getters.get_live_backend_accounts.length > 0)?_c('dl',[_c('div',{staticClass:"sm:px-8 sm:py-2"},[_c('dt',{staticClass:"mb-1 text-sm leading-5 font-normal"},[_vm._v(" "+_vm._s(_vm.$t("select-account"))+" ")]),_c('dd',{staticClass:"text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2 flex"},_vm._l((_vm.get_live_backend_accounts),function(account,index){return _c('button',{key:index,staticClass:"cursor-pointer p-5 border rounded mr-3 w-full focus:outline-none",class:_vm.selectedAccount === account ? 'border-blue-500' : '',attrs:{"id":"account_1","type":"reset"},on:{"click":function($event){return _vm.setAccount(account)}}},[(
                            account.backend.name ===
                            'Cloud-SingleCurrencyTrading'
                          )?_c('img',{staticClass:"m-auto",attrs:{"src":_vm.selectedAccount === account
                              ? require(("../../assets/icon-" + (account.backend.name) + "-white.png"))
                              : require(("../../assets/icon-" + (account.backend.name) + ".png")),"alt":"Meta Logo"}}):_c('img',{staticClass:"m-auto",attrs:{"src":_vm.selectedAccount === account
                              ? require(("../../assets/" + (account.backend.type) + "-white.png"))
                              : require(("../../assets/" + (account.backend.type) + ".png")),"alt":"Meta Logo"}})])}),0)])]),_c('div',{staticClass:"sm:px-8 mt-5"},[_c('dt',{staticClass:"mb-1 text-sm leading-5 font-normal"},[_vm._v(" "+_vm._s(_vm.$t("Currency"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('div',{staticClass:"sm:col-span-2"},[(_vm.selectedAccount.backend)?_c('ValidationProvider',{attrs:{"name":"password_current","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.get_live_backend_accounts.find(
                              function (x) { return x.backend === _vm.selectedAccount.backend; }
                            ).currencies,"placeholder":_vm.$t(
                              'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                            ),"label":"symbol"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1)])])]):_vm._e()]),(_vm.$store.getters.get_live_backend_accounts.length > 0)?_c('div',{staticClass:"flex-shrink-0 px-8 py-4 space-x-4 flex justify-end"},[(_vm.$store.getters.get_profile.state === 'Full')?_c('span',{staticClass:"inline-flex rounded-md shadow-sm"},[_c('Button',{staticClass:"relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo",attrs:{"disabled":_vm.isDisabled,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("create-account"))+" ")])],1):_vm._e()]):_vm._e()])])],1)]}}],null,false,3551769428)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }