var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('navigation',{attrs:{"show-language-switcher":true,"show-login":true,"show-user-menu":false}}),_c('div',{staticClass:"min-h-screen w-full bg-gray-100 flex flex-col justify-center m-auto"},[_c('div',{staticClass:"sm:mx-auto sm:w-full sm:max-w-md"},[_c('h2',{staticClass:"mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900"},[_vm._v(" "+_vm._s(_vm.$t("reset-trading-account-password"))+" ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"mt-8 sm:mx-auto sm:w-full sm:max-w-lg"},[_c('div',{staticClass:"bg-white shadow rounded-sm m-4"},[_c('div',{staticClass:"px-6 pt-6 pb-2 sm:grid sm:grid-cols-3 sm:gap-4"},[_c('dt',{staticClass:"text-sm leading-5 font-normal my-auto text-left"},[_vm._v(" "+_vm._s(_vm.$t("new-password"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('ValidationProvider',{attrs:{"rules":{
                    required: true,
                    regex: /^(?=.*[A-Z])(?=.*[0-9]).{8,15}$/,
                  },"name":"confirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_new),expression:"password_new"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"password_new","type":"password"},domProps:{"value":(_vm.password_new)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_new=$event.target.value}}})]),(errors[0] === 'confirm')?_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(" "+_vm._s(_vm.$t( "password-must-contain-minimum-8-characters-including-one-uppercase-letter-and-one-number" )))]):_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"px-6 pt-6 pb-6 sm:grid sm:grid-cols-3 sm:gap-4"},[_c('dt',{staticClass:"text-sm leading-5 font-normal my-auto text-left"},[_vm._v(" "+_vm._s(_vm.$t("confirm-new-password"))+" ")]),_c('dd',{staticClass:"mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"},[_c('ValidationProvider',{attrs:{"rules":"password:@confirm|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password_confirm),expression:"password_confirm"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"password_confirm","type":"password"},domProps:{"value":(_vm.password_confirm)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password_confirm=$event.target.value}}})]),(errors[0] === 'Passwords do not match')?_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(" "+_vm._s(_vm.$t( "your-passwords-do-not-match-please-check-and-try-again" )))]):_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"p-4 flex justify-between sm:justify-end border-t"},[_c('span',{staticClass:"inline-flex rounded-md shadow-sm sm:mr-4"},[_c('Button',{staticClass:"relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("set-new-password"))+" ")])],1),_c('span',{staticClass:"inline-flex rounded-md shadow-sm"},[_c('button',{staticClass:"relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50",attrs:{"type":"reset"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])])])])])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }